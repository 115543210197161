

.jobs {
  display: flex;
  flex-direction: column;
  // border: 1px solid black;
  list-style: none;
  padding: 0;
  margin: 0;
}

.jobs > li {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  // border: 1px solid blue;
  padding-bottom: 1em;
}

.cv-meta {
  flex: 0 0 auto;
  width: 7.8em;
  padding: 0.3em 0.8em 0 0;
  border-right: 2px solid darkgray;
  text-align: right;
  font-size: 0.8em;
}

.cv-date-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 0.5em;

  >span {
    text-align: right;
  }

}

.date-separator {
  color:darkgray;
}


.cv-main {
  flex: 2px 2px 2px;
  flex-grow: 1;
  padding: 0 0 0 0.8em;
  // border: 1px solid yellow;
}


.cv-entry-title {
  font-weight: bold;
  font-size: 1.1em;
}

.cv-entry-subtitle {
  // font-style: italic;
  font-size: 0.8em;
}

.cv-project {

  >h2 {
    // font-weight: normal;
    font-size: 0.9em;
    margin: 0;
    padding: 0 0 0.2em 0;
    border: none;
  }
}

.cv-tag-container {
  display: flex;
  flex-wrap: wrap;
  padding: 0.2em 0 0.8em 0;
}

.cv-tag-container > span {
  padding: 0.1em 0.2em;
  margin: 2px;
  background: $footer-background-color;
  border-radius: 2px;
  font-size: 13px;
  color: white;
}

.cv-bullets {
  margin: 0;
  padding-left: 1.1em;
  list-style: disc;
  font-size: 0.8em;
  list-style-position: outside;
}

.cv-bullets > li {
  margin: 0.2em 0;
}
















.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted $primary-color;
}

.tooltip .tooltiptext {
  width: 120px;
  bottom: 100%;
  left: 50%;
  margin-left: -60px; /* Use half of the width (120/2 = 60), to center the tooltip */
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}
